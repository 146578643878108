<template>
  <main class="main_inner">
    <div class="grey-bg">
      <HeaderInter v-if="showheaderbtn" :back-path="'opapp'" />
      <Header />
      <HomeSearch
        v-if="banners['index_big_banner'] !== undefined"
        :class="{ mtTop: showheaderbtn }"
        :banners="banners['index_big_banner'].content"
      />
      <HomeNav />
      <HomeSilder
        v-if="
          banners['index_hot_banner'] !== undefined &&
            banners['index_hot_banner'].content.length > 0
        "
        :banners="banners['index_hot_banner'].content"
      />
      <div v-if="banners['index_hot_items'] !== undefined">
        <HomeList
          v-for="(item, index) in banners['index_hot_items'].content"
          :key="index"
          :hot-item="item"
        />
      </div>
      <QuickIcon />
    </div>
  </main>
</template>
<script>
import HeaderInter from '@/components/HeaderInter'
import Header from '@/components/Header'
import HomeSearch from '@/components/HomeSearch'
import HomeNav from '@/components/HomeNav'
import HomeSilder from '@/components/HomeSilder'
import HomeList from '@/components/HomeList'
import QuickIcon from '@/components/QuickIcon'
import { getShopInfo } from '@/api/shop'
import { showNotice } from '@/utils/tool'

export default {
  name: 'Index',
  components: {
    HeaderInter,
    Header,
    HomeSearch,
    HomeNav,
    HomeSilder,
    HomeList,
    QuickIcon
  },
  data() {
    return {
      productCategoryList: []
    }
  },

  computed: {
    showheaderbtn() {
      if (this.$store.getters.isinapp && this.$store.getters.isinapp.name) {
        return true
      } else {
        return false
      }
    },
    banners() {
      return this.$store.getters.banner || {}
    }
  },
  beforeCreate() {},
  created() {
    document.getElementsByTagName('body')[0].style.overflow = 'auto'
    getShopInfo()
      .then(response => {
        // 比對版本
        const clientVersion = parseFloat(process.env.VUE_APP_VERSION)
        const serverVersion = parseFloat(response.data.data.version)
        if (serverVersion > clientVersion) {
          showNotice('請重新載入頁面 更新程式版本', function() {
            window.location.reload()
          })
        }

        this.$store.commit('SET_INFO', response.data.data)
      })
      .catch(() => {})
  },
  destroyed() {}
}
</script>
<style lang="scss">
.mtTop {
  margin-top: 40px;
}
</style>
