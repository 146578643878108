<template>
  <div>
    <div
      v-if="!isswiper"
      id="kv-area"
      class="kv-area"
      :style="
        'background: linear-gradient( 180deg, rgba(255, 255, 255, 0) 80%, white 100%), url(' +
          getRandomImg() +
          ') no-repeat center center;background-size: cover;'
      "
      @click="toLink($event)"
    >
      <fixed-header :threshold="160">
        <div class="inter-search">
          <div class="input-group">
            <div class="input-group-prepend">
              <select
                id=""
                name=""
                class="form-control"
                @change="setArea($event)"
              >
                <option
                  v-for="(area, index) in areaList"
                  :key="index"
                  :value="area.area_id"
                >
                  {{ area.name }}
                </option>
              </select>
            </div>
            <div class="search-area">
              <input
                v-model="keyword"
                type="text"
                class="searchTerm"
                placeholder="hi~你想去哪裡?"
                @keyup.enter="productSearch($event)"
              >
              <button
                type="submit"
                class="searchButton"
                @click="productSearch()"
              >
                <i class="fa fa-search" />
              </button>
            </div>
          </div>
        </div>
      </fixed-header>
    </div>
    <div v-else class="htopslider-area">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide
          v-for="(bitem, index) in banners"
          :key="index"
          class="hometopswiper-slide"
          :style="'background-image: url(' + bitem.img + ')'"
          @click.native="toswiperLink(bitem.link)"
        />
      </swiper>
      <div class="inter-search">
        <div class="input-group">
          <div class="input-group-prepend">
            <select
              id=""
              name=""
              class="form-control"
              @change="setArea($event)"
            >
              <option
                v-for="(area, index) in areaList"
                :key="index"
                :value="area.area_id"
              >
                {{ area.name }}
              </option>
            </select>
          </div>
          <div class="search-area">
            <input
              v-model="keyword"
              type="text"
              class="searchTerm"
              placeholder="hi~你想去哪裡?"
              @keyup.enter="productSearch($event)"
            >
            <button type="submit" class="searchButton" @click="productSearch()">
              <i class="fa fa-search" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FixedHeader from 'vue-fixed-header'
import { showNotice } from '@/utils/tool'
import { getArea } from '@/api/shop'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'Timer',
  components: {
    FixedHeader,
    Swiper,
    SwiperSlide
  },
  props: {
    banners: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      item: {
        img: require('@/assets/images/kv.jpg'),
        link: ''
      },
      keyword: null,
      fixed: false,
      areaList: [],
      isswiper: false,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: {
          delay: 5000, // 5秒切換一次
          stopOnLastSlide: false,
          disableOnInteraction: true
        },
        // centeredSlides: true,
        loop: true,
        // loopAdditionalSlides: 100,
        breakpoints: {
          768: {
            slidesPerView: 1,
            spaceBetween: 0
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  created() {
    getArea().then(response => {
      this.areaList = [
        {
          name: '全部',
          area_id: 0
        }
      ]
      this.areaList = [...this.areaList, ...response.data.data]
    })
    if (this.banners.length === 1) {
      this.item = this.banners[Math.floor(Math.random() * this.banners.length)]
    } else if (this.banners.length > 1) {
      this.isswiper = true
    }
  },
  methods: {
    getRandomImg() {
      return this.item.img
    },
    toswiperLink(e) {
      location.href = e
    },
    toLink(e) {
      if (e.target.id === 'kv-area' && this.item.link !== '') {
        location.href = this.item.link
      }
    },
    setArea(event) {
      if (event.target.value !== 0) {
        this.$router.push('/product/area/' + event.target.value)
      }
    },
    productSearch(e) {
      if (!this.keyword || this.keyword.trim() === '') {
        showNotice('請輸入關鍵字')
      } else {
        this.keyword = this.keyword.replace('/', '').trim()
        if (this.keyword === '') {
          showNotice('請輸入合法關鍵字')
        } else {
          this.$router.push('/product/keyword/' + this.keyword)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import 'assets/scss/common.scss';
.htopslider-area {
  position: relative !important;
  width: 100%;
  background-color: transparent;
  .swiper {
    .hometopswiper-slide {
      max-width: 100%;
      height: 140px;
      background-position: center;
      background-size: cover !important;
      background-repeat: no-repeat;
    }
  }

  .inter-search {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1000;
    border-radius: 0px;
    padding: 0px 20px;
    box-shadow: 1px 10px 10px 0px rgba($main-color, $alpha: 0.1);
    .input-group {
      position: relative;
      width: 100%;
      display: flex;
      margin: 0 auto;
    }
    .form-control {
      display: block;
      min-width: 120px;
      width: 100%;
      height: 36px;
      padding: 0.35rem 2rem 0.55rem;
      font-weight: 400;
      text-align: center;
      line-height: 1.5;
      background-clip: padding-box;
      border: 1px solid rgba($main-color, $alpha: 1);
      border-radius: 3rem 0rem 0rem 3rem;
      -webkit-transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    }
    select.form-control {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: url(~@/assets/images/arrow-down.svg) no-repeat
        calc(100% - 15px) center;
      background-size: 10px;
      border: none;
      background-color: rgba($main-color, $alpha: 1);
      color: rgba($white-color, $alpha: 1);
    }
  }
  .search-area {
    flex: 0 0 calc(100% - 130px);
    .searchTerm {
      width: 100%;
      height: 36px;
      padding: 15px 25px 15px 25px;
      border-radius: 0 10px 10px 0;
      outline: none;
      margin: auto;
      background: rgba($white-color, $alpha: 0.8);
      border: 1px solid rgba($main-color, $alpha: 0.6);
    }
    .searchTerm:focus {
      color: $sec-color;
    }
    .searchButton {
      width: 36px;
      height: 36px;
      background: $main-color;
      text-align: center;
      color: $white-color;
      border-radius: 50px 50px;
      cursor: pointer;
      position: absolute;
      right: 0;
      outline: none;
      margin: auto;
      border: none;
    }
    .searchButton i {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
.kv-area {
  height: 140px;
  background: linear-gradient(
      180deg,
      rgba($grey-bg, $alpha: 0) 50%,
      rgba($grey-bg, $alpha: 1) 100%
    ),
    // linear-gradient(0deg, rgba($sec-color, $alpha: 0) 0%, rgba($black-color, $alpha: 1) 100%),
      url(~@/assets/images/kv.jpg) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 1rem;
  .inter-search {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    border: 1px solid rgba($main-color, $alpha: 0.25);
    border-radius: 50px;
    .input-group {
      position: relative;
      width: 100%;
      display: flex;
    }
    .form-control {
      display: block;
      min-width: 120px;
      width: 100%;
      height: 36px;
      padding: 0.35rem 1.5rem 0.55rem;
      font-weight: 400;
      text-align: center;
      line-height: 1.5;
      background-clip: padding-box;
      border: 1px solid rgba($main-color, $alpha: 1);
      border-radius: 3rem 0rem 0rem 3rem;
      -webkit-transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    }
    select.form-control {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: url(~@/assets/images/arrow-down.svg) no-repeat
        calc(100% - 15px) center;
      background-size: 10px;
      border: none;
      background-color: rgba($main-color, $alpha: 1);
      color: rgba($white-color, $alpha: 1);
    }
  }

  .search-area {
    flex: 0 0 calc(100% - 130px);
    .searchTerm {
      width: 100%;
      height: 36px;
      padding: 15px 25px 15px 25px;
      border-radius: 0 10px 10px 0;
      outline: none;
      margin: auto;
      border: 0;
      background: rgba($white-color, $alpha: 0.8);
    }
    .searchTerm:focus {
      color: $sec-color;
    }
    .searchButton {
      width: 36px;
      height: 36px;
      background: $main-color;
      text-align: center;
      color: $white-color;
      border-radius: 50px 50px;
      cursor: pointer;
      position: absolute;
      right: 0;
      outline: none;
      margin: auto;
      border: none;
    }
    .searchButton i {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
.inter-search.vue-fixed-header--isFixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
  background: rgba($white-color, $alpha: 1);
  border-radius: 0px;
  padding: 10px 20px;
  border-bottom: 1px solid rgba($sec-color, $alpha: 0.2);
  box-shadow: 1px 10px 10px 0px rgba($main-color, $alpha: 0.1);
  .searchTerm {
    border: 1px solid rgba($main-color, $alpha: 0.6);
  }
}
@media screen and (min-width: 401px) and (max-width: 500px) {
  .htopslider-area {
    .swiper {
      .hometopswiper-slide {
        height: 173px;
      }
    }
  }
  .kv-area {
    height: 173px;
  }
}
@media screen and (min-width: 501px) and (max-width: 600px) {
  .htopslider-area {
    .swiper {
      .hometopswiper-slide {
        height: 217px;
      }
    }
  }
  .kv-area {
    height: 217px;
  }
}
@media screen and (min-width: 601px) and (max-width: 699px) {
  .htopslider-area {
    .swiper {
      .hometopswiper-slide {
        height: 260px;
      }
    }
  }
  .kv-area {
    height: 260px;
  }
}
@media screen and (min-width: 700px) {
  .htopslider-area {
    .swiper {
      .hometopswiper-slide {
        height: 275px;
      }
    }
    .inter-search {
      border-bottom: 1px solid rgba($sec-color, $alpha: 0.2);
      .input-group {
        width: 70%;
      }
    }
  }
  .kv-area {
    height: 275px;
    .inter-search {
      width: 70%;
      .searchTerm {
        border: 1px solid rgba($main-color, $alpha: 0.6);
      }
    }
  }
  .inter-search.vue-fixed-header--isFixed {
    .searchTerm {
      border: 1px solid rgba($main-color, $alpha: 0.6);
    }
  }
}

  .inter-search.vue-fixed-header--isFixed {
    @media screen and (min-width: 640px) {
      width: 640px;
      max-width: 640px;
      margin: auto;
    }

    @media screen and (min-width: 768px) {
      width: 768px;
      max-width: 768px;
    }
  }
</style>
