<template>
  <div class="prodlist-area">
    <h2>{{ hotItem.name }}</h2>

    <swiper class="swiper" :options="swiperOption">
      <swiper-slide v-for="(item, index) in hotItem.items" :key="index">
        <div class="card-items" @click="toItemDetail(item)">
          <div
            class="img"
            :style="'background-image: url(' + item.cover_image + ')'"
          />
          <p class="name">
            {{ item.name }}
          </p>
          <p
            v-if="item.origin_price && item.origin_price <= item.min_price"
            class="price-2"
          >
            優惠價
          </p>
          <p v-else-if="item.origin_price" class="price-o">
            NT${{ item.origin_price | priceFormat }}
          </p>
          <p v-if="item.min_price < 1" class="price">
            <span v-show="item.point !== null && item.point > 0">
              {{ item.point | priceFormat }}點</span>
          </p>
          <p v-else class="price">
            <span class="sign">NT$</span>{{ item.min_price | priceFormat }}
            <span v-if="item.point !== null && item.point > 0" class="price-1">
              +{{ item.point | priceFormat }}點</span>
          </p>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'SwiperExampleFreeMode',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    hotItem: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 2.5,
        spaceBetween: 20,
        freeMode: true,
        breakpoints: {
          768: {
            slidesPerView: 2.15,
            spaceBetween: 10
          }
        }
      }
    }
  },
  methods: {
    toItemDetail(item) {
      this.$router.push('/product/detail/' + item.item_id)
    }
  }
}
</script>

<style scoped lang="scss">
@import 'assets/scss/common.scss';
.prodlist-area {
  margin-bottom: 1.5rem;
  padding: 0 15px;
  font-family: 微軟正黑體;
  h2 {
    font-size: 1.15rem;
    color: $font-color;
    margin: 0 0 1.15rem;
    padding: 0;
    text-align: left;
    font-weight: bold;
  }
  .card-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .card-items {
    flex: 0 0 calc(50% - 10px);
    margin-bottom: 1.5rem;
    padding-bottom: 0.65rem;
    background: $white-color;
    border-radius: 0 0 4px 4px;
    -webkit-box-shadow: 1px 1px 3px -1px #999;
    box-shadow: 1px 1px 3px -1px #999;
    .img {
      margin-bottom: 0.5rem;
      background-size: cover !important;
      display: block;
      max-width: 100%;
      height: 0;
      overflow: hidden;
      padding-bottom: 66%;
      border-radius: 4px 4px 0 0;
      // box-shadow: 0px 1px 5px 0px rgba($black-color, $alpha: 0.2);
    }
    .img img {
      border-radius: 4px;
    }
    p {
      margin: 0;
      padding: 0 0.65rem;
      font-size: 1rem;
      line-height: 1.35rem;
    }
    .name {
      font-size: 1rem;
      margin-bottom: 5px;
      color: $font-color;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      height: 2.65rem;
    }
    .price {
      font-size: 1rem;
      color: $price-color;
      font-family: 'Roboto', sans-serif;
      font-weight: bolder !important;
      .sign {
        font-size: 0.5rem;
      }
    }
    .price-o {
      font-size: 0.75rem;
      color: rgba($font-color, $alpha: 0.5);
      font-family: 'Roboto', sans-serif;
      text-decoration: line-through;
    }
    .price-1 {
      font-size: 0.75rem;
      color: rgba($font-color, $alpha: 0.5);
      font-family: 'Roboto', sans-serif;
    }

    .price-2 {
      font-size: 0.75rem;
      color: $price-color;
      font-family: 'Roboto', sans-serif;
    }
  }
}

.img.a {
  background: url(~@/assets/images/6.jpg) no-repeat center;
}
.img.b {
  background: url(~@/assets/images/2.jpg) no-repeat center;
}
.img.c {
  background: url(~@/assets/images/3.jpg) no-repeat center;
}
.img.d {
  background: url(~@/assets/images/4.jpg) no-repeat center;
}
.img.e {
  background: url(~@/assets/images/5.jpg) no-repeat center;
}
</style>
