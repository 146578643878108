<template>
  <div class="nav-area container-fluid">
    <swiper
      v-if="productCategoryList.length > 0"
      class="swiper"
      :options="swiperOption"
    >
      <swiper-slide
        v-for="(item, index) in productCategoryList"
        :key="index"
        @click.native="toItemList(item)"
      >
        <i :style="'background-color:' + item.color">
          <img :src="item.img" alt="" class="iconimg">
        </i>
        <p>
          <small>{{ item.name }}</small>
        </p>
      </swiper-slide>
      <div slot="pagination" class="swiper-pagination" />
    </swiper>
  </div>
</template>

<script>
import { directive, Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { getCatogory } from '@/api/shop'
import { setCateClass } from '@/utils/tool'

export default {
  name: 'SwiperExampleSlidesPerColumn',
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
      swiperOption: {
        direction: 'horizontal',
        slidesPerView: 4,
        slidesPerColumn: 2,
        spaceBetween: 5,
        allowTouchMove: true,
        slidesPerColumnFill: 'row'
      },
      productCategoryList: []
    }
  },
  async created() {
    try {
      const response = await getCatogory()
      this.productCategoryList = response.data.data
      const paginationClass =
        this.productCategoryList.length > 8 ? '.swiper-pagination' : '.none'
      this.swiperOption.pagination = {
        el: paginationClass,
        clickable: true
      }
    } catch (error) {
      // error
    }
  },
  methods: {
    setCateClass(cate_id) {
      return setCateClass(cate_id)
    },
    toItemList(item) {
      this.$router.push('/product/cate/' + item.category_id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/scss/common.scss';
.nav-area {
  margin: 0 auto;
  padding: 1.5rem 0 1rem;
}
.swiper {
  height: 200px;
  padding-bottom: 1rem;
  .swiper-slide {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    i {
      width: 60px;
      height: 60px;
      border-radius: 60px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .iconimg {
        width: 60px;
        height: 60px;
      }
    }
    p {
      line-height: 0.85rem;
      margin: 0.5rem 0 0;
      padding: 0;
      color: $font-color;
      small {
        font-size: 1rem;
      }
    }
  }
}

.swiper-slide:nth-child(1) i {
  background: rgba($eating_item, $alpha: 1);
}
.swiper-slide:nth-child(2) i {
  background: rgba($hotspring_color, $alpha: 1);
}
.swiper-slide:nth-child(3) i {
  background: rgba($living_item, $alpha: 1);
}
.swiper-slide:nth-child(4) i {
  background: rgba($spa_item, $alpha: 1);
}
.swiper-slide:nth-child(5) i {
  background: rgba($amusement_item, $alpha: 1);
}
.swiper-slide:nth-child(6) i {
  background: rgba($traffic_item, $alpha: 1);
}
.swiper-slide:nth-child(7) i {
  background: rgba($wifi_sim_item, $alpha: 1);
}
.swiper-slide:nth-child(8) i {
  background: rgba($travil_item, $alpha: 1);
}
.swiper-slide:nth-child(9) i {
  background: rgba($allitem_color, $alpha: 1);
}
</style>
