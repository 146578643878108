<template>
  <header v-if="logo" class="header_area">
    <img :src="logo" class="logo-img" alt="logo">
  </header>
</template>

<script>
// import { getImgUrl } from '@/utils/tool'

export default {
  data() {
    return {
      logo: null
      // logo: require('@/assets/images/logo-3trip.svg')
    }
  },
  created() {
    if (
      this.$store.getters.shopInfo &&
      this.$store.getters.shopInfo.logo !== undefined
    ) {
      this.logo = this.$store.getters.shopInfo.logo
    }
  }
}
</script>

<style scoped lang="scss">
@import 'assets/scss/common.scss';

.header_area {
  padding: 12px 0;
  background: $white-color;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo-img {
    height: 20px;
    max-width: 100%;
    object-fit: contain;
    object-position: center;
  }
}
</style>
