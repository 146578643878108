<template>
  <div class="container-fluid px-3 pb-3">
    <swiper class="swiper" :options="swiperOption" @click-slide="handleClickSlide">
      <swiper-slide
        v-for="(item, index) in banners"
        :key="index"
        :style="'background-image: url('+item.img+')'"
      />
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'SwiperExampleCenteredSlides',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    banners: {
      type: Array,
      default: () => []
    }
  },
  title: 'Centered slides',
  data() {
    return {
      swiperOption: {
        slidesPerView: 2.2,
        spaceBetween: 20,
        autoplay: {
          delay: 5000, // 5秒切換一次
          stopOnLastSlide: false,
          disableOnInteraction: true
        },
        // centeredSlides: true,
        // loop: true,
        // loopAdditionalSlides: 100,
        breakpoints: {
          768: {
            slidesPerView: 1.15,
            spaceBetween: 20
          }
        },
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true,
        // },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  methods: {
    handleClickSlide(index, reallyIndex) {
      const item = this.banners[index]
      if (item !== undefined && item.link !== '') {
        location.href = item.link
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "assets/scss/common.scss";
  .swiper-slide {
    opacity: 1;
    background: url(~@/assets/images/2.jpg) no-repeat center;
    display: block;
    max-width: 100%;
    height: 0;
    background-size: cover !important;
    overflow: hidden;
    padding-bottom: 50%; /*16:9*/
    border-radius: 4px;
    margin-bottom: 1.5rem;
  }
  .swiper-slide:nth-child(1) {
  }
  .swiper-slide-active {
    opacity: 1;
  }
  .swiper-button-next {
    background-image: url(~@/assets/images/icon-arrow-r.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    right: 0px;
    top: 50%;
    opacity: 1;
  }
  .swiper-button-prev {
    background-image: url(~@/assets/images/icon-arrow-l.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    left: 0px;
    top: 50%;
    opacity: 0.8;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
  }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    opacity: 1;
  }
  @media screen and (min-width: 768px) {
    .swiper-slide {
      padding-bottom: 30%; /*16:9*/
    }
    .swiper-slide {
      opacity: 1;
    }
  }
</style>
